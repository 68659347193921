<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="favicon.ico"
          transition="scale-transition"
          width="40"
        />
        <span class="font-weight-light white--text">My</span>
        <span class="font-weight-bold white--text">APP</span>
        <span class="font-weight-light white--text">2</span>
      </div>
    </v-app-bar>

    <!-- <v-main>
      <router-view/>
    </v-main> -->
    <v-content>
      <section id="hero">
        <v-row no-gutters>
          <v-img
            :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'"
            src="https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1951&q=80"
          >
            <v-theme-provider dark>
              <v-container fill-height>
                <v-row
                  align="center"
                  class="white--text mx-auto"
                  justify="center"
                >
                  <v-col
                    class="white--text text-center"
                    cols="12"
                    tag="h1"
                  >
                    <span
                    :class="[$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2']"
                      class="font-weight-light"
                    >
                      SANDE presents
                    </span>

                    <br>

                    <span
                      :class="[$vuetify.breakpoint.smAndDown ? 'display-3': 'display-4']"
                      class="font-weight-black"
                    >
                      MyApp2
                    </span>

                  </v-col>

                  <v-btn
                    class="align-self-end"
                    fab
                    outlined
                    @click="$vuetify.goTo('#about-me')"
                  >
                    <v-icon>mdi-chevron-double-down</v-icon>
                  </v-btn>
                </v-row>
              </v-container>
            </v-theme-provider>
          </v-img>
        </v-row>
      </section>

      <section id="about-me">
        <div class="py-12"></div>

        <v-container class="text-center">
          <h2 class="display-2 font-weight-bold mb-3">Got an App Idea?</h2>

          <v-responsive
            class="mx-auto mb-8"
            width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-responsive
            class="mx-auto title font-weight-light mb-8 text-justify"
            max-width="720"
          >
            SANDE Consulting is a Texas based business (North Dallas). MyApp2 is one of our division focused on developing apps for anyone that has a brilliant app idea. If your idea will make people's live easier or help a process be more efficient or bring happiness, you probably have a big hit waiting to happen. Partner up with MyApp2 and we will build your app at no cost. Let us go to business together...
          </v-responsive>
          <v-responsive
            class="mx-auto title"
            max-width="720"
          >
            Contact: Kyle Tran Hernandez
          </v-responsive>
          <v-responsive
            class="mx-auto title"
            max-width="720"
          >
            Cell: 469-525-0450
          </v-responsive>
          <v-responsive
            class="mx-auto title mb-8"
            max-width="720"
          >
            Email: kh2kt@yahoo.com
          </v-responsive>
                            <v-btn
                    class="align-self-end"
                    fab
                    outlined
                    @click="$vuetify.goTo('#features')"
                  >
                    <v-icon>mdi-chevron-double-down</v-icon>
                  </v-btn>
        </v-container>

        <div class="py-12"></div>
      </section>

      <section
        id="features"
        class="grey lighten-3"
      >
        <div class="py-12"></div>

        <v-container class="text-center">
          <h2 class="display-2 font-weight-bold mb-3">MyAPP2 Products</h2>
                  <v-btn
                    class="align-self-end"
                    fab
                    outlined
                    @click="$vuetify.goTo('#products')"
                  >
                    <v-icon>mdi-chevron-double-down</v-icon>
                  </v-btn>

          <v-row>
            <v-col
              v-for="({ icon, title, text }, i) in features"
              :key="i"
              cols="12"
              md="4"
            >
              <v-card
                class="py-12 px-4"
                color="grey lighten-5"
                flat
              >
                <v-theme-provider dark>
                  <div>
                    <v-avatar
                      color="primary"
                      size="88"
                    >
                      <v-icon
                        large
                        v-text="icon"
                      ></v-icon>
                    </v-avatar>
                  </div>
                </v-theme-provider>

                <v-card-title
                  class="justify-center font-weight-black text-uppercase"
                  v-text="title"
                ></v-card-title>

                <v-card-text
                  class="subtitle-1"
                  v-text="text"
                >
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <div class="py-12"></div>
      </section>

      <section id="stats">
        <v-parallax
          :height="$vuetify.breakpoint.smAndDown ? 700 : 500"
          src="https://images.unsplash.com/photo-1510915228340-29c85a43dcfe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80"
        >
          <v-container fill-height>
            <v-row class="mx-auto">
              <v-col
                v-for="[value, title] of stats"
                :key="title"
                cols="12"
                md="3"
              >
                <div class="text-center">
                  <div
                    class="display-3 font-weight-black mb-4"
                    v-text="value"
                  ></div>

                  <div
                    class="title font-weight-regular text-uppercase"
                    v-text="title"
                  ></div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-parallax>
      </section>

    </v-content>

    <section id="products">
    <v-footer

      color="#292929"
      height="1600"
    >
    <div class="mt-10">
      <v-row>
        <h2 class="mt-10 mx-4 white--text font-weight-light"><a href="https://www.therapyimages.com/">Therapy Images by AI</a> - With the goal of making it easier for therapists to help special need kids, this webapp uses AI generated images to assists with visualization and understanding. Once an image is presented, AI ask a question pertaining to the objective of the images. After an answer is submitted, AI evaluates and keeps track of progress. </h2>
      </v-row>
      <v-row>
        <h2 class="mt-10 mx-4 white--text font-weight-light"><a href="https://www.wingmateai.com/">Wingmate by AI</a> - For socially challenged individuals looking for someone special, AI can be there for you as your personal wingman to help with openers or fill-in awkward silent moments.</h2>
      </v-row>
      <v-row>
        <h2 class="mt-10 mx-4 white--text font-weight-light"><a href="https://www.tutoredbrain.com">tutored brAIn</a> - With the goal of improving readers' comprehension, AI generates short creative and engaging passages to help capture readers' focus. Afterwards, AI asks questions about the passage, evaluate answers, and keep track of score. </h2>
      </v-row>
      <v-row>
        <h2 class="mt-10 mx-4 white--text font-weight-light"><a href="https://www.search4teams.com">Search4Teams</a> - Making it easier for baseball/softball coaches to find players (under age of 18) by 
          making it easier for parents to find teams  </h2>
      </v-row>
      <v-row>
        <h2 class="mt-10 mx-4 white--text font-weight-light"><a href="https://tracker.kylesdev.com">Progress Tracker</a> - Tracks how baseball/softball players are performing with regards hitting, pitching, throwing, and running  </h2>
      </v-row>
      <v-row>
        <h2 class="mt-10 mx-4 white--text font-weight-light"><a href="https://bestfundraising.myapp2.net">High Tech Fundraiser</a> - A special fundraising app for youth sports or school clubs. It incorporated a Standford psychology study and the latest technologies 
          to come up with a unique approach in maximizing donations.</h2>
      </v-row>
      <v-row>
        <h2 class="mt-10 mx-4 white--text font-weight-light"><a href="https://scheduleintro.myapp2.net">Online Calendar</a> - Help business owners prevent schedule misunderstandings and frustrations by allowing
          their customers to directly schedule requests themselves. Other online calendars are too expensive and rigid. With a cost effective subscription, we can customize
          the online calendar to the business' needs. This will help customers manage their service requests and track progress from their handheld devices.
          Furthermore, service technicians are able to use the same calendar to pull out information on what service calls they have to attend to for that day or week
          without the business owner getting involved in manually providing a list of assignments.</h2>
      </v-row>
      <v-row>
        <h2 class="mt-10 mx-4 white--text font-weight-light"><a href="https://scoresheet.myapp2.net">Digitized Score Sheet</a> -  Provides a faster, more convenient and accurate system to help judges score event activities. 
          Convert your paper score sheets to online score sheets. Eliminate waste and save the planet at the same time.
          Moreover, save time by allowing the system to perform the score calculations accurately and avoid any embarrassing human errors.
        </h2>
      </v-row>
    </div>


    <v-row class="mt-4">
      
    </v-row>
    
    </v-footer>
    </section>

  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
